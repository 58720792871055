import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AppComponent } from './app.component'
import { HomeComponent } from './pages/home/home.component'
import { LoginComponent } from './pages/login/login.component'
import { PrivatehomeComponent } from './pages/privatehome/privatehome.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { DataStorageComponent } from './pages/data-storage/data-storage.component'
import { PageNotFoundComponent } from './pages/page-not-found-componentmponent/page-not-found-componentmponent.component'
import { FormsModule } from '@angular/forms'
import { GraficoHotelComponent } from './components/grafico-hotel/grafico-hotel.component'
import { ManagementAreaComponent } from './pages/management-area/management-area.component'
import { FileUploadComponent } from './components/file-upload/file-upload.component'
import { GeneradorPdfComponent } from './components/generador-pdf/generador-pdf.component'
import { GraficoTipoTuristaComponent } from './components/grafico-tipoTurista/grafico-TipoTurista.component'
//import { GraficoHabitacionesOcupadasComponent } from './components/grafico-habitaciones-ocupadas/grafico-habitacionesOcupadas.component';
import { GraficoMapaComponent } from './components/grafico-mapa/grafico-mapa.component'
import { PlotlyModule } from 'angular-plotly.js'
import * as PlotlyJS from 'plotly.js-dist-min'
import { GraficoReusableComponent } from './components/grafico-reusable/grafico-reusable.component'
import { CardAlojamientoComponent } from './components/card-alojamiento/card-alojamiento.component'
import { GraficoPorEdadComponent } from './components/grafico-por-edad/grafico-por-edad.component'
import { PageUnderConstructionComponent } from './pages/page-under-construction/page-under-construction.component'
import { ArticleComponent } from './pages/article/article.component'
import { BlogComponent } from './pages/blog/blog.component'
import { KnowledgeAreaComponent } from './pages/knowledge-area/knowledge-area.component'
import { AccountComponent } from './pages/account/account.component'
import { GraficoPdfComponent } from './components/grafico-pdf/grafico-pdf.component'
import { ConvertirGraficaPdfComponent } from './components/convertirGraficaPdf/convertirGraficaPdf.component'
import { AuthInterceptor } from './interceptors/auth.interceptor'
import { TiempoComponent } from './components/MapaTiempo/tiempo.component'
import { ReactiveFormsModule } from '@angular/forms'
import { DatosCLMComponent } from './pages/datos-clm/datos-clm.component'
import { TablasFicheroComponent } from './components/tablas-fichero/tablas-fichero.component'
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from './components/header/header.component'
import { ChangePassModalComponent } from './components/change-pass-modal/change-pass-modal.component'
import { VerificationCodeFormComponent } from './pages/login/components/verification-code-form/verification-code-form.component'
import { ConfirmationCodeFormComponent } from './pages/login/components/confirmation-code-form/confirmation-code-form.component'
import { VinculateMfaFormComponent } from './pages/login/components/vinculate-mfa-form/vinculate-mfa-form.component'
import { SignupFormComponent } from './pages/login/components/signup-form/signup-form.component'
import { SigninFormComponent } from './pages/login/components/signin-form/signin-form.component'
import { ResetPasswordFormComponent } from './pages/login/components/reset-password-form/reset-password-form.component'
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component'
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component'
import { FullCalendarModule } from '@fullcalendar/angular'
import { CalendarioComponent } from './components/calendario/calendario.component'
import { FormatFechaPipe } from './pipes/format-fecha.pipe'
import { FormatTempPipe } from './pipes/format-temp.pipe'
import { ToastrModule } from 'ngx-toastr'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ModalCalendarComponent } from './components/calendario/modal-calendar/modal-calendar.component'
import { MatDialogModule } from '@angular/material/dialog'
import { GraficoVolumenTuristaComponent } from './components/grafico-volumen-turista/grafico-volumen-turista.component'
import { GraficoPaqueteComponent } from './components/grafico-paquete/grafico-paquete.component'
import { GraficoPuntosTuristicosComponent } from './components/grafico-puntos-turisticos/grafico-puntos-turisticos.component'
import { GraficoGradoOcupacionComponent } from './components/grafico-grado-ocupacion/grafico-grado-ocupacion.component'
import { GraficoPuntoProvinciaComponent } from './components/grafico-punto-provincia/grafico-punto-provincia.component'
import { MatIconModule } from '@angular/material/icon'
import { ModalConfirmarComponent } from './components/calendario/modal-confirmar/modal-confirmar.component'

import { GraficoTuristasPorCcaaComponent } from './components/grafico-turistas-por-ccaa/grafico-turistas-por-ccaa.component'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { GraficoOrigenComponent } from './components/grafico-origen/grafico-origen.component'
import { GraficoTipoAlojamientoComponent } from './components/grafico-tipo-alojamiento/grafico-tipo-alojamiento.component'
import { GraficoVolumenPuntoComponent } from './components/grafico-volumen-punto/grafico-volumen-punto.component'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { ModalGuardarComponent } from './components/calendario/modal-guardar/modal-guardar.component'
import { GraficoIndicadoresOcupacionComponent } from './components/grafico-indicadores-ocupacion/grafico-indicadores-ocupacion.component'
import { GraficoEstimacionHabitacionesPlazasComponent } from './components/grafico-estimacion-habitaciones-plazas/grafico-estimacion-habitaciones-plazas.component'
import { GraficoEstablecimientosAbiertosComponent } from './components/grafico-establecimientos-abiertos/grafico-establecimientos-abiertos.component'
import { GraficoDuracionMotivComponent } from './components/grafico-duracion-motiv/grafico-duracion-motiv.component'
import { GraficoGastosMotivComponent } from './components/grafico-gastos-motiv/grafico-gastos-motiv.component'
import { GraficoMediaMovilComponent } from './components/grafico-media-movil/grafico-media-movil.component'
import { GraficoGastosProvinciasComponent } from './components/grafico-gastos-provincias/grafico-gastos-provincias.component'
import { GraficoGastosMotivosComponent } from './components/grafico-gastos-motivos/grafico-gastos-motivos.component'
import { GraficoGastosActividadesComponent } from './components/grafico-gastos-actividades/grafico-gastos-actividades.component'
import { GraficoContribucionComponent } from './components/grafico-contribucion/grafico-contribucion.component'

import { Grafica3DComponent } from './components/grafica3-d/grafica3-d.component'
import { ConfirmarCambioHotelModalComponent } from './components/calendario/modal-cambio-hotel/modal-cambio-hotel.component'
import { ChatbotComponent } from './components/chatbot/chatbot.component'
// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin,
//   listPlugin,
//   interactionPlugin
// ])

PlotlyModule.plotlyjs = PlotlyJS
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    PrivatehomeComponent,
    DashboardComponent,
    DataStorageComponent,
    PageNotFoundComponent,
    GraficoHotelComponent,
    ManagementAreaComponent,
    FileUploadComponent,
    GeneradorPdfComponent,
    GraficoReusableComponent,
    GraficoTipoTuristaComponent,
    //GraficoHabitacionesOcupadasComponent,
    GraficoMapaComponent,
    CardAlojamientoComponent,
    GraficoPorEdadComponent,
    PageUnderConstructionComponent,
    ArticleComponent,
    BlogComponent,
    KnowledgeAreaComponent,
    AccountComponent,
    GraficoPdfComponent,
    ConvertirGraficaPdfComponent,
    TiempoComponent,
    DatosCLMComponent,
    TablasFicheroComponent,
    FooterComponent,
    HeaderComponent,
    ChangePassModalComponent,
    ConfirmationModalComponent,
    NotificationModalComponent,
    CalendarioComponent,
    FormatFechaPipe,
    FormatTempPipe,
    ModalCalendarComponent,
    GraficoVolumenTuristaComponent,
    GraficoPaqueteComponent,
    GraficoPuntosTuristicosComponent,
    GraficoGradoOcupacionComponent,
    GraficoPuntoProvinciaComponent,
    ModalConfirmarComponent,
    GraficoOrigenComponent,
    GraficoTipoAlojamientoComponent,
    GraficoVolumenPuntoComponent,
    GraficoTuristasPorCcaaComponent,
    ModalGuardarComponent,
    GraficoIndicadoresOcupacionComponent,
    GraficoEstimacionHabitacionesPlazasComponent,
    GraficoEstablecimientosAbiertosComponent,
    GraficoDuracionMotivComponent,
    GraficoGastosMotivComponent,
    GraficoMediaMovilComponent,
    GraficoGastosProvinciasComponent,
    GraficoGastosMotivosComponent,
    GraficoGastosActividadesComponent,
    GraficoContribucionComponent,
    Grafica3DComponent,
    ConfirmarCambioHotelModalComponent,
    ChatbotComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    PlotlyModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FullCalendarModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    VerificationCodeFormComponent,
    ConfirmationCodeFormComponent,
    VinculateMfaFormComponent,
    SignupFormComponent,
    SigninFormComponent,
    ResetPasswordFormComponent,
    ReactiveFormsModule,
    FullCalendarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
  ],
  providers: [
    provideClientHydration(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
