import { ElementRef, Injectable } from '@angular/core'
import { Chart } from 'chart.js'
import * as noUiSlider from 'nouislider'
import { CSVData } from '../files/iCSV'

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  private motiveMap: { [key: string]: { description: string; color: string } } =
    {
      '1': {
        description: 'Turismo de sol y playa',
        color: 'rgba(255, 99, 132, 1.0)',
      }, // Rosa vibrante
      '2': {
        description: 'Turismo cultural',
        color: 'rgba(54, 162, 235, 1.0)',
      }, // Azul vibrante
      '3': {
        description: 'Turismo de naturaleza',
        color: 'rgba(255, 206, 86, 1.0)',
      }, // Amarillo cálido
      '4': {
        description: 'Turismo gastronómico',
        color: 'rgba(75, 192, 192, 1.0)',
      }, // Turquesa claro
      '5': {
        description: 'Turismo deportivo',
        color: 'rgba(153, 102, 255, 1.0)',
      }, // Morado suave
      '6': {
        description: 'Turismo termal y de bienestar',
        color: 'rgba(255, 159, 64, 1.0)',
      }, // Naranja claro
      '7': {
        description: 'Otro tipo de turismo de ocio',
        color: 'rgba(183, 77, 51 , 1.0)',
      }, // Melocotón
      '8': {
        description: 'Visitas a familiares o amigos',
        color: 'rgba(128, 205, 160, 1.0)',
      }, // Verde menta
      '9': {
        description: 'Turismo de compras',
        color: 'rgba(230, 128, 255, 1.0)',
      }, // Lavanda
      '10': {
        description: 'Desplazamiento al centro de estudios habitual',
        color: 'rgba(108, 128, 255, 1.0)',
      }, // Azul lavanda
      '11': {
        description: 'Otros motivos de educación y formación',
        color: 'rgba(255, 128, 171, 1.0)',
      }, // Rosa suave
      '12': {
        description: 'Tratamiento de salud',
        color: 'rgba(64, 200, 240, 1.0)',
      }, // Cian suave
      '13': {
        description: 'Motivos religiosos o peregrinaciones',
        color: 'rgba(200, 160, 255, 1.0)',
      }, // Lila claro
      '14': { description: 'Incentivos', color: 'rgba(255, 192, 112, 1.0)' }, // Beige cálido
      '15': { description: 'Otros motivos', color: 'rgba(144, 238, 144, 1.0)' }, // Verde pastel
      '16': {
        description: 'Congresos, ferias y convenciones',
        color: 'rgba(255, 182, 193, 1.0)',
      },
      '17': {
        description: 'Desplazamiento al centro de trabajo',
        color: 'rgba(192, 192, 255, 1.0)',
      }, // Azul hielo
      '18': {
        description: 'Otros motivos profesionales',
        color: 'rgba(255, 140, 140, 1.0)',
      }, // Rojo pastel
    }
  private provinceMap: { [key: string]: string } = {
    '45': 'Toledo',
    '2': 'Albacete',
    '13': 'Ciudad Real',
    '16': 'Cuenca',
    '19': 'Guadalajara',
    CLM: 'Castilla-La Mancha',
  }
  private communitieMap: { [key: string]: string } = {
    // '0': 'Extranjero',
    '1': 'Andalucía',
    '2': 'Aragón',
    '3': 'Principado de Asturias',
    '4': 'Islas Baleares',
    '5': 'Islas Canarias',
    '6': 'Cantabria',
    '7': 'Castilla y León',
    '8': 'Castilla-La Mancha',
    '9': 'Cataluña',
    '10': 'Comunidad Valenciana',
    '11': 'Extremadura',
    '12': 'Galicia',
    '13': 'Comunidad de Madrid',
    '14': 'Región de Murcia',
    '15': 'Comunidad Foral de Navarra',
    '16': 'País Vasco',
    '17': 'La Rioja',
    '18': 'Ceuta',
    '19': 'Melilla',
  }
  activityMap: { [key: string]: string } = {
    acti_golf: 'Golf',
    acti_depor: 'Deportes de invierno',
    acti_navega: 'Navegación en barco',
    acti_otrosnauticos: 'Otros deportes náuticos',
    acti_caza: 'Caza',
    acti_sender: 'Senderismo, montañismo',
    acti_aventura: 'Deportes de aventura-riesgo',
    acti_caballo: 'Rutas a caballo',
    acti_otrosedportes: 'Otros deportes',
    acti_especdepor: 'Asistencia a espectáculos deportivos',
    acti_visitascultu: 'Visitas culturales',
    acti_especultu: 'Asistencia a espectáculos culturales',
    acti_otrascultu: 'Otras actividades culturales',
    acti_ciudades: 'Visitar ciudades',
    acti_rurales: 'Visitar destinos rurales, ir de camping',
    acti_tematicos: 'Visitas a parques temáticos',
    acti_relax: 'Servicios tipo balneario, talasoterapia',
    acti_juegos: 'Visitas a casinos, salas de juego',
    acti_gastro: 'Actividades gastronómicas',
    acti_diver: 'Actividades de diversión',
    acti_religion: 'Acontecimientos religiosos',
    acti_congresos: 'Congresos y ferias',
    acti_areasnatu: 'Visitas a áreas naturales',
    acti_playa: 'Disfrute y uso de la playa',
    acti_familia: 'Visita a familiares y amigos',
    acti_compras: 'Compras',
  }

  constructor() {}

  filterDataByYear(data: any[], year: string) {
    return data.filter((item) => item.anyo === year)
  }

  calculatePercentages(data: any[]): Record<number, Record<number, number>> {
    const percentages: Record<number, Record<number, number>> = {}

    const yearTypeCounts: Record<number, Record<number, number>> = {}
    const yearTotalResponses: Record<number, number> = {}

    for (const entry of data) {
      const year = +entry.anyo
      const type = +entry.tipoviaj

      if (!yearTypeCounts[year]) {
        yearTypeCounts[year] = {}
        yearTotalResponses[year] = 0
      }

      yearTypeCounts[year][type] = (yearTypeCounts[year][type] || 0) + 1
      yearTotalResponses[year] += 1
    }
    for (const year in yearTypeCounts) {
      const totalResponses = yearTotalResponses[+year]
      percentages[+year] = {}

      for (let type = 1; type <= 10; type++) {
        const count = yearTypeCounts[+year]?.[type] || 0 // Obtener el conteo, o 0 si no existe
        const percentage = (count / totalResponses) * 100
        percentages[+year][type] = parseFloat(percentage.toFixed(2)) // Calcular el porcentaje
      }
    }

    return percentages
  }

  calculatePercentagesMotiv(
    data: any[]
  ): Record<number, Record<number, number>> {
    const percentages: Record<number, Record<number, number>> = {}

    const yearTypeCounts: Record<number, Record<number, number>> = {}
    const yearTotalResponses: Record<number, number> = {}

    for (const entry of data) {
      const year = +entry.anyo
      const type = +entry.motiv
      if (!yearTypeCounts[year]) {
        yearTypeCounts[year] = {}
        yearTotalResponses[year] = 0
      }

      yearTypeCounts[year][type] = (yearTypeCounts[year][type] || 0) + 1
      yearTotalResponses[year] += 1
    }
    for (const year in yearTypeCounts) {
      const totalResponses = yearTotalResponses[+year]
      percentages[+year] = {}

      for (let type = 1; type <= 18; type++) {
        const count = yearTypeCounts[+year]?.[type] || 0 // Obtener el conteo, o 0 si no existe
        const percentage = (count / totalResponses) * 100
        percentages[+year][type] = parseFloat(percentage.toFixed(2)) // Calcular el porcentaje
      }
    }

    return percentages
  }

  calculatePercentagePaquete(data: any[]) {
    const validResponses = data.filter((item) => item.paquete !== '0.0')

    const total = validResponses.length

    const counts = {
      yes: validResponses.filter((item) => item.paquete === '1.0').length,
      no: validResponses.filter((item) => item.paquete === '6.0').length,
      dontKnow: validResponses.filter((item) => item.paquete === '8.0').length,
    }
    const percentages = [
      { label: 'Sí', value: (counts.yes / total) * 100 },
      { label: 'No', value: (counts.no / total) * 100 },
      { label: 'No sabe', value: (counts.dontKnow / total) * 100 },
    ]

    const filteredData = percentages.filter((item) => item.value > 0)
    return {
      labels: filteredData.map((item) => item.label),
      values: filteredData.map((item) => item.value),
    }
  }

  extractYearsAndMonths(data: any[], months: string[]): Map<string, string[]> {
    const yearMonthMap = new Map<string, string[]>()
    let monthsArray: string[] = []
    data.forEach((dato) => {
      if (dato.periodo) {
        const year = dato.periodo.slice(0, 4)
        const monthIndex = parseInt(dato.periodo.slice(5)) - 1
        const month = months[monthIndex]

        if (!yearMonthMap.has(year)) {
          yearMonthMap.set(year, [])
        }
        monthsArray = yearMonthMap.get(year)!
        if (!monthsArray.includes(month)) {
          monthsArray.push(month)
        }
      }
    })
    yearMonthMap.forEach((monthsArray) => {
      monthsArray.sort((a, b) => months.indexOf(a) - months.indexOf(b))
    })
    return yearMonthMap
  }

  filterDataByDateRange(
    data: any[],
    startYear: number,
    startMonth: string,
    endYear: number,
    endMonth: string,
    months: string[]
  ): any[] {
    const startDate = parseInt(
      `${startYear}${String(months.indexOf(startMonth) + 1).padStart(2, '0')}`,
      10
    )
    const endDate = parseInt(
      `${endYear}${String(months.indexOf(endMonth) + 1).padStart(2, '0')}`,
      10
    )
    return data.filter((dato) => {
      if (dato.periodo !== undefined) {
        const date = dato.periodo.slice(0, 4) + dato.periodo.slice(5)

        return date >= startDate && date <= endDate
      }
      return false
    })
  }
  getProvinceInfo(province: string) {
    switch (province.toLowerCase()) {
      case 'albacete':
        return { codigo: '02', nombre: 'albacete' }
      case 'ciudad real':
        return { codigo: '13', nombre: 'ciudad_real' }
      case 'cuenca':
        return { codigo: '16', nombre: 'cuenca' }
      case 'guadalajara':
        return { codigo: '19', nombre: 'guadalajara' }
      case 'toledo':
        return { codigo: '45', nombre: 'toledo' }
      default:
        return { codigo: '00', nombre: '' }
    }
  }
  getMotive(motive: string): string {
    return this.motiveMap[motive]?.description
  }
  getMotiveColor(description: string): string {
    for (const key in this.motiveMap) {
      const motive = this.motiveMap[key]
      if (motive.description === description) {
        return motive.color
      }
    }

    return 'rgba(200, 200, 200, 0.5)'
  }
  getProvince(province: string): string {
    return this.provinceMap[province]
  }
  getCommunity(community: string): string {
    return this.communitieMap[community]
  }
  getActivity(activity: string): string {
    return this.activityMap[activity]
  }
  getProvinceKeys(province: string) {
    for (let key in this.provinceMap) {
      if (this.provinceMap[key] === province) {
        return key
      }
    }
    return undefined
  }
}
