<section class="chatbot">
  <div class="chat" [class.active]="showChat">
    <header>
      <p><b>Chat con Sendy</b></p>
      <button (click)="showChat = false">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </header>
    <div #conversationBox class="chat__conversation">
      @for (entry of conversation; track $index) {
        <div class="chat__conversation__entry">
          @if (entry.from === 'bot') {
            <span class="botProfile">🤖</span>
          }
          <div
            class="message"
            [class.message--strong]="$index === 0"
            [class.message--error]="entry.type === 'error'"
            [class.message--bot]="entry.from === 'bot'"
            [class.message--user]="entry.from === 'user'"
          >
            {{ entry.message }}
          </div>
        </div>
      }
    </div>
    <form class="chat__write" (submit)="sendMessage()">
      <input
        type="text"
        name="message"
        placeholder="Pregunta lo que quieras..."
        [(ngModel)]="message"
      />
      <button type="submit" [disabled]="waiting">
        @if (waiting) {
          <img
            width="18"
            src="assets/images/icons/loading-white.svg"
            alt="Loading"
          />
        } @else {
          <i class="fa-solid fa-paper-plane" style="color: #f4f5f6"></i>
        }
      </button>
    </form>
  </div>
  <div class="chatButton" (click)="showChat = !showChat">
    @if (showChat === true) {
      <button>
        <i class="fa-solid fa-xmark"></i>
      </button>
    } @else {
      <span>🤖</span>
    }
    <p><b>Chat con Sendy</b></p>
  </div>
</section>
