import { Component, ElementRef, ViewChild } from '@angular/core'
import { Chart, registerables } from 'chart.js'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { HttpClient } from '@angular/common/http'
import * as noUiSlider from 'nouislider'
import { GraphService } from '../../services/graficos/graph.service'

@Component({
  selector: 'app-grafico-media-movil',
  templateUrl: './grafico-media-movil.component.html',
  styleUrl: './grafico-media-movil.component.scss',
})
export class GraficoMediaMovilComponent {
  chartMediaMovil: Chart
  months: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
  data: any[] = []
  filteredData: any[] = []
  years: any[] = []
  selectedStartMonth = ''
  selectedStartYear = 0
  selectedEndMonth = ''
  selectedEndYear = 0
  dynamicMonthsByYear: Map<string, string[]>

  @ViewChild('mediaMovilProvinces', { static: true })
  mediaMovilProvinces!: ElementRef<HTMLCanvasElement>
  @ViewChild('rangeSlider', { static: true }) rangeSlider!: ElementRef

  constructor(
    private fileService: FileUploadService,
    private http: HttpClient,
    private graphService: GraphService
  ) {
    Chart.register(...registerables)
  }

  ngOnInit() {
    this.loadCSVData()
  }
  loadCSVData() {
    this.http
      .get('../../../assets/data/medias_moviles_3m.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        this.data = this.fileService.parseCSVData(data)
        const yearMonthMap = this.graphService.extractYearsAndMonths(
          this.data,
          this.months
        )
        this.years = Array.from(yearMonthMap.keys()).sort()
        this.dynamicMonthsByYear = yearMonthMap
        this.initializeSlider()
      })
  }
  initializeSlider() {
    const slider = this.rangeSlider.nativeElement
    if (!this.rangeSlider) return

    const sliderValues: string[] = []
    this.years.forEach((year) => {
      const months = this.dynamicMonthsByYear.get(year)!
      months.forEach((month) => {
        sliderValues.push(`${month} ${year}`)
      })
    })

    noUiSlider.create(slider, {
      start: [sliderValues[0], sliderValues[10]],
      step: 1,
      range: {
        min: 0,
        max: sliderValues.length - 1,
      },
      tooltips: [true, true],
      format: {
        to: (value) => {
          return sliderValues[Math.round(value)]
        },
        from: (value) => {
          return sliderValues.indexOf(value)
        },
      },
    })

    slider.noUiSlider!.on('update', (values: any, handle: any) => {
      const start = values[0]
      const end = values[1]

      const [startMonth, startYear] = start.split(' ')
      const [endMonth, endYear] = end.split(' ')

      this.selectedStartYear = parseInt(startYear)
      this.selectedStartMonth = startMonth
      this.selectedEndYear = parseInt(endYear)
      this.selectedEndMonth = endMonth

      this.updateChartByDateRange()
    })
  }
  updateChartByDateRange() {
    this.filteredData = []
    this.filteredData = this.graphService.filterDataByDateRange(
      this.data,
      this.selectedStartYear,
      this.selectedStartMonth,
      this.selectedEndYear,
      this.selectedEndMonth,
      this.months
    )
    this.createChart()
  }

  createChart() {
    const canvasElement = this.mediaMovilProvinces.nativeElement
    const gasto = this.filteredData.map((d) => {
      if (d) {
        return d[`gasto_promedio_movil_3m`]
      }
    })
    const duracion = this.filteredData.map((d) => {
      if (d) {
        return d[`duracion_promedio_movil_3m`]
      }
    })
    const gastoColor = 'rgba(75, 192, 192, 1.0)'

    const duracionColor = 'rgba(255, 159, 64, 1.0)'

    if (this.chartMediaMovil) {
      this.chartMediaMovil.destroy()
    }
    const chartConfig = {
      type: 'scatter',
      data: {
        labels: this.filteredData.map((d) => {
          const year = d['periodo'].slice(0, 4)
          const monthIndex = parseInt(d['periodo'].slice(5)) - 1 // convierte a índice (0-11)
          return `${this.months[monthIndex]} ${year}`
        }),
        datasets: [
          {
            type: 'line',
            label: 'Gasto Promedio Móvil General (3 meses)',
            data: gasto,
            borderColor: gastoColor,
            yAxisID: 'y',
          },
          {
            type: 'line',
            label: 'Duración Promedio Móvil General (3 meses)',
            data: duracion,
            borderColor: duracionColor,
            yAxisID: 'y1',
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            min: 120, //Revisar para futuras gráficas
            max: 160, //Revisar para futuras gráficas
            ticks: {
              color: 'rgba(75, 192, 192, 1.0)',
              beginAtZero: true,
              stepSize: 4,
            },
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Gasto (euros)',
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            min: 2,
            max: 4,
            gtid: {
              drawOnChartArea: false,
            },
            ticks: {
              color: 'rgba(255, 159, 64, 1.0)',
              beginAtZero: true,
              stepSize: 0.2,
            },
            title: {
              display: true,
              text: 'Duración (meses)',
            },
          },
        },
        plugins: {
          datalabels: false,
          title: {
            display: true,
            text: 'Evolución Temporal de Gasto por Noche',
            font: {
              size: 24,
            },
            padding: {
              top: 30,
              bottom: 30,
            },
          },
        },
      },
    }

    this.chartMediaMovil = new Chart(canvasElement, chartConfig as any)
  }
}
