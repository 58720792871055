<div>
  <div>
    <label for="provinceSelect">Selecciona una provincia:</label>
    <select
      id="provinceSelect"
      [(ngModel)]="selectedProvince"
      (change)="onProvinceChange($event)"
    >
      <option *ngFor="let province of provinces" [value]="province">
        {{ province }}
      </option>
    </select>
  </div>
  <div class="mt-3">
    <label for="yearSelect">Selecciona un año:</label>
    <select
      id="yearSelect"
      [(ngModel)]="selectedYear"
      (change)="onYearChange($event)"
    >
      <option *ngFor="let year of yearsWithoutUndefined" [value]="year">
        {{ year }}
      </option>
    </select>
  </div>

  <canvas #gastosPorActividad id="gastosPorActividad"></canvas>
</div>
