import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, ViewChild, viewChild } from '@angular/core'
import { FileUploadService } from '../../services/files/fileUpload.service'
import { Chart, registerables } from 'chart.js'
import { GraphService } from '../../services/graficos/graph.service'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'

@Component({
  selector: 'app-grafico-gastos-motiv',
  templateUrl: './grafico-gastos-motiv.component.html',
  styleUrl: './grafico-gastos-motiv.component.scss',
})
export class GraficoGastosMotivComponent {
  chart: Chart | undefined
  data: any[] = []
  filteredData: { [province: string]: number[] } = {}
  provinces: string[] = []
  motives: string[] = []
  @ViewChild('gastosMotiv', { static: true }) gastosMotiv!: ElementRef

  constructor(
    private http: HttpClient,
    private fileService: FileUploadService,
    private graphService: GraphService
  ) {
    Chart.register(MatrixController, MatrixElement)
    Chart.register(...registerables)
  }

  ngOnInit() {
    this.loadCSVData()
  }

  loadCSVData() {
    this.http
      .get('../../../assets/data/gasto_promedio_provdest_dataset.csv', {
        responseType: 'text',
      })
      .subscribe((data) => {
        const processedData = this.fileService.parseCSVData(data)
        this.data = this.prepareMatrixData(processedData)
        this.updateChart()
      })
  }
  prepareMatrixData(data: any[]): any[] {
    const result: any[] = []
    this.provinces = [
      ...new Set(
        data.map((row) => this.graphService.getProvince(row.provdest))
      ),
    ]
    this.motives = [
      ...new Set(data.map((row) => this.graphService.getMotive(row.motiv))),
    ]
    data.forEach((row) => {
      const motive = this.graphService.getMotive(row.motiv)
      const province = this.graphService.getProvince(row.provdest)
      const gasto = row.gasto_prom_motiv_prov
      if (province && motive && !isNaN(gasto)) {
        result.push({
          x: province,
          y: motive,
          v: gasto,
        })
      }
    })
    this.provinces.forEach((province) => {
      this.motives.forEach((motive) => {
        const exists = result.some(
          (item) => item.x === province && item.y === motive
        )
        if (!exists) {
          result.push({
            x: province,
            y: motive,
            v: 0,
          })
        }
      })
    })

    const resultWithoutUndefined = result.filter((result) => {
      return result.x !== 'undefined' && !result.undefined
    })

    return resultWithoutUndefined
  }
  updateChart() {
    const spectrumLegendPlugin = {
      id: 'spectrum_legend',
      beforeDraw(chart: any) {
        const { ctx, chartArea } = chart

        const legendWidth = 250
        const legendHeight = 20
        const legendX = chartArea.left + (chartArea.width - legendWidth) / 2
        const legendY = chartArea.bottom + 50

        const gradient = ctx.createLinearGradient(
          legendX,
          legendY,
          legendX + legendWidth,
          legendY
        )
        gradient.addColorStop(0, '#FF8C42') // Naranja
        gradient.addColorStop(0.5, '#FFD966') // Amarillo
        gradient.addColorStop(1, '#8FCF8C') // Verde

        ctx.fillStyle = gradient
        ctx.fillRect(legendX, legendY, legendWidth, legendHeight)

        const labels = ['0 €', '150 €', '200 €']
        const positions = [0, 0.5, 0.85]
        labels.forEach((label, index) => {
          const x = legendX + positions[index] * legendWidth
          const y = legendY + legendHeight + 15
          ctx.fillStyle = '#4B4B4B'
          ctx.font = '12px Arial'
          ctx.textAlign = 'center'
          ctx.fillText(label, x, y)
        })
      },
    }
    if (this.chart) {
      this.chart.destroy()
    }

    const chartConfig = {
      type: 'matrix',
      data: {
        datasets: [
          {
            label: 'Gasto Promedio por Provincia y Motivo',
            data: this.data,
            backgroundColor: (context: any) => {
              const value = context.dataset.data[context.dataIndex].v
              const max = 300 // Valor máximo para la escala
              const min = 0 // Valor mínimo para la escala

              // Crear un gradiente interpolado
              const gradientColors = [
                { stop: 0, color: [255, 140, 66] },
                { stop: 0.5, color: [255, 217, 102] },
                { stop: 1, color: [143, 207, 140] },
              ]

              // Normalizar el valor entre 0 y 1
              const normalizedValue = (value - min) / (max - min)

              // Encontrar los stops del gradiente correspondientes
              for (let i = 0; i < gradientColors.length - 1; i++) {
                const start = gradientColors[i]
                const end = gradientColors[i + 1]
                if (
                  normalizedValue >= start.stop &&
                  normalizedValue <= end.stop
                ) {
                  const ratio =
                    (normalizedValue - start.stop) / (end.stop - start.stop)

                  // Interpolar colores
                  const r = Math.round(
                    start.color[0] + ratio * (end.color[0] - start.color[0])
                  )
                  const g = Math.round(
                    start.color[1] + ratio * (end.color[1] - start.color[1])
                  )
                  const b = Math.round(
                    start.color[2] + ratio * (end.color[2] - start.color[2])
                  )

                  return `rgb(${r}, ${g}, ${b})`
                }
              }

              return '#8FCF8C' // Color por defecto si no coincide
            },
            width: (context: any) => {
              const chartArea = context.chart.chartArea || {}
              const xAxis = context.chart.scales.x
              return chartArea.width / xAxis.ticks.length
            },
            height: (context: any) => {
              const chartArea = context.chart.chartArea || {}
              const yAxis = context.chart.scales.y

              return chartArea.height / yAxis.ticks.length
            },
            borderColor: 'rgba(255,255,255)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Promedio de gastos por motivo de viaje de cada provincia',
            font: {
              size: 24,
            },
            padding: {
              top: 10,
              bottom: 30,
            },
          },
          datalabels: false,
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                const value = context.raw.v
                return `Gasto: ${value}€`
              },
            },
          },
        },
        layout: {
          padding: {
            top: 30,
            bottom: 80, // Agregar espacio entre la leyenda y el gráfico
          },
        },
        scales: {
          x: {
            type: 'category',
            offset: true,
            labbels: Array.from(new Set(this.data.map((item) => item.x))),
            title: {
              display: true,
              text: 'Provincia',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            type: 'category',
            reverse: false,
            offset: true,
            labels: Array.from(new Set(this.data.map((item) => item.y))),
            title: {
              display: true,
              text: 'Motivos de Viaje',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      },
      plugins: [spectrumLegendPlugin],
    }
    this.chart = new Chart(this.gastosMotiv.nativeElement, chartConfig as any)
  }
}
