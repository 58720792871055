<header class="navigation" [ngClass]="{ 'header-sticky-on': isScrolled }">
  <div class="container">
    <nav class="navbar navbar-expand-xl navbar-light text-center">
      <div class="container-fluid">
        <a class="navbar-brand" routerLink="/home">
          <img
            loading="prelaod"
            decoding="async"
            class="img-fluid"
            width="100"
            src="assets/images/logo-senda.svg"
            alt="Wallet"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-between"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mb-2 mb-lg-0 text-uppercase fs-7">
            <li class="nav-item">
              <a class="nav-link" routerLink="/home">Home</a>
            </li>

            <!-- <li class="nav-item "> <a class="nav-link" href="privatehome" (click)="nothing($event)">Plataforma</a></li> -->
            <!-- account btn -->
            <a routerLink="/app" class="nav-link"> Area privada</a>
            <!-- <li class="nav-item "> <a (click)="nothing($event)" class="nav-link" href="page-under-construction">Testimonios</a></li> -->
            <li class="nav-item">
              <a class="nav-link" routerLink="/blog">Últimas noticias</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/datosAbiertosCLM"
                >Datos Abiertos CLM</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="goToFaqs()">FAQs</a>
            </li>
            <!-- <li class="nav-item "> <a (click)="nothing($event)" class="nav-link" href="page-under-construction">Áreas de conocimiento</a></li> -->
          </ul>

          <a
            *ngIf="!authService.isLoggedIn()"
            routerLink="/login"
            class="btn btn-outline-dark"
            [ngClass]="{ disabled: isDisabled }"
            ><i class="fa-solid fa-user me-2" id="acceder"></i> Acceso
          </a>

          <!-- elemento login -->
          <li
            *ngIf="authService.isLoggedIn()"
            class="nav-item dropdown border border-dark rounded ms-auto removeLi"
          >
            <a
              class="avatar avatar-sm p-0 show"
              id="profileDropdown"
              role="button"
              data-bs-auto-close="outside"
              data-bs-display="static"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <!-- <img
                class="avatar-img rounded-2"
                src="assets/images/avatar.svg"
                alt="avatar"
              /> -->
              <div class="avatar-img rounded-2">
                <span class="initial2">{{ initialName }}</span>
              </div>
            </a>
            <ul
              class="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-lg-start shadow border-0 p-3"
            >
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar me-3">
                    <div class="user-initial">
                      <span class="initial">{{ logolName }}</span>
                    </div>
                    <!-- <img class="avatar-img rounded-circle shadow-sm" src="assets/images/gestur.svg" alt="avatar"> -->
                  </div>
                  <div class="account">
                    <a class="h6 mt-2 mt-sm-0 text-nowrap"> {{ nombre }} </a>
                    <p class="small m-0 text-nowrap">{{ correo }}</p>
                  </div>
                </div>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" [routerLink]="['/account']">
                  <i class="fa-solid fa-user me-2 text-secondary"></i> Perfil</a
                >
                <!-- redirigir a account -->
              </li>
              <li class="d-none">
                <a class="dropdown-item" href="#">
                  <i class="fa-solid fa-cog me-2 text-secondary"></i>
                  Configuración</a
                >
              </li>
              <li class="d-none">
                <a class="dropdown-item" href="#">
                  <i class="fa-solid fa-circle-info me-2 text-secondary"></i>
                  Configuración</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  (click)="deslog()"
                  [ngClass]="{ disabled: isDisabled }"
                >
                  <i
                    class="fa-solid fa-power-off me-2 text-secondary"
                    (click)="deslog()"
                  ></i>
                  Salir</a
                >
              </li>
            </ul>
          </li>
        </div>
      </div>
    </nav>
  </div>
</header>
<div id="sticky-space" class="" [ngClass]="{ active: isScrolled }"></div>
<router-outlet class="router" />

<footer class="section-sm">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-lg-6 col-md-4 col-6 mb-4">
        <div class="footer-widget">
          <h5 class="mb-4 fw-bolder">
            Conocemos su negocio. Lo hacemos sencillo.<br />Lo hacemos tuyo.
          </h5>
          <img
            loading="prelaod"
            class="img-fluid"
            width="100px"
            src="assets/images/logo-senda.svg"
            alt="senda"
          />
          <p>2023 © Copyrights por senda</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="col">
          <span class="mb-4 fw-bolder">Dirección</span>
          <p class="mb-0">
            Calle del Cervantes, 10 <br />
            13001 Ciudad Real <br />Castilla-La Mancha <br />España
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="col">
          <span class="mb-4 fw-bolder">Contacto</span>
          <p class="mb-0">
            informacióngestur.com <br />
            Teléfono: +34 123 456 789<br />
          </p>
          <ul class="list-unstyled list-inline mb-0 social-icons mt-2">
            <li class="list-inline-item me-3">
              <a title="Twitter Profile" class="text-body fs-3" href="#"
                ><i class="fa-brands fa-x-twitter"></i
              ></a>
            </li>
            <li class="list-inline-item me-3">
              <a title="Instagram Profile" class="text-body fs-3" href="#"
                ><i class="fa-brands fa-instagram"></i
              ></a>
            </li>
            <li class="list-inline-item me-3">
              <a title="Facebook Profile" class="text-body fs-3" href="#"
                ><i class="fa-brands fa-facebook-f"></i
              ></a>
            </li>
            <li class="list-inline-item me-3">
              <a title="Linkedin Profile" class="text-body fs-3" href="#">
                <i class="fa-brands fa-linkedin-in"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="container copyright">
  <div class="row align-items-center my-5 text-center text-md-start">
    <div class="col-lg-7">
      <div class="d-flex justify-content-between">
        <a href="index.html">
          <img
            loading="prelaod"
            class="img-fluid"
            width=""
            src="assets/images/Logo-Financiado-porUE.svg"
            alt="UE"
          />
        </a>
        <a href="index.html">
          <img
            loading="prelaod"
            class="img-fluid"
            width=""
            src="assets/images/Logotipo_del_Ministerio.svg"
            alt="Ministerio"
          />
        </a>
        <a href="index.html">
          <img
            loading="prelaod"
            class="img-fluid"
            width=""
            src="assets/images/Logo_PRTR_tres_lineas_COLOR.svg"
            alt="PRTR"
          />
        </a>
        <a href="index.html">
          <img
            loading="prelaod"
            class="img-fluid"
            width=""
            src="assets/images/jccm.svg"
            alt="jccm"
          />
        </a>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
      <ul class="list-unstyled list-inline mb-0 text-lg-end text-uppercase">
        <li class="list-inline-item me-4">
          <a class="text-black" href="privacy-policy.html"
            >POLITICA DE PRIVACIDAD</a
          >
        </li>
        <li class="list-inline-item me-4">
          <a class="text-black" href="terms.html">AVISO LEGAL</a>
        </li>
      </ul>
    </div>
    <div class="col-lg-4 col-md-6 text-md-end mt-4 mt-md-0"></div>
  </div>
</div>
@if (authService.isLoggedIn()) {
  <app-chatbot></app-chatbot>
}
