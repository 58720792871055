import { Component, ViewChild, ElementRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { firstValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment'

interface chatMessage {
  from: 'bot' | 'user'
  type: 'chat' | 'error'
  message: string
}

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrl: './chatbot.component.scss',
})
export class ChatbotComponent {
  showChat: boolean = false
  waiting: boolean = false
  message = ''
  @ViewChild('conversationBox') conversationBox: ElementRef

  conversation: chatMessage[] = [
    {
      from: 'bot',
      type: 'chat',
      message: '¿En qué puedo ayudarte?',
    },
  ]

  constructor(private http: HttpClient) {}

  async sendMessage() {
    const message = this.message
    this.message = ''
    this.conversation.push({
      from: 'user',
      type: 'chat',
      message: message,
    })
    this.waiting = true
    this.scrollDown()
    try {
      const response = await this.sendQuestion(message)

      this.conversation.push({
        from: 'bot',
        type: 'chat',
        message: response,
      })
    } catch (err: any) {
      console.log(err)
      this.conversation.push({
        from: 'bot',
        type: 'error',
        message:
          'No puedo atenderle debido a un error en la petición. Intentelo más tarde por favor.',
      })
    } finally {
      this.scrollDown()
      this.waiting = false
    }
  }

  scrollDown() {
    setTimeout(() => {
      this.conversationBox.nativeElement.scroll({
        top: this.conversationBox.nativeElement.scrollHeight,
        behavior: 'smooth',
      })
    })
  }

  async sendQuestion(question: string): Promise<string> {
    const alejandriaResponse = (await firstValueFrom(
      this.http.post(environment.alejandria, {
        prompt: question,
        model: 0,
        temperature: 0.5,
        open_button: false,
      })
    )) as any

    if (!alejandriaResponse.answer) {
      throw new Error()
    }

    return alejandriaResponse.answer
  }
}
