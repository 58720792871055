export const baseUrl = 'https://api.sendaclm.es:8443'
export const environment = {
  production: true,

  UserPoolId: 'eu-south-2_c23sy1viw',
  ClientId: '4hm6bnen5b4c2ekmol8fsaptj',

  login: baseUrl + '/auth/login',
  register: baseUrl + '/usuarios/registro',
  getUserInfoUrl: baseUrl + '/usuarios/perfil',
  updateUserInfoUrl: baseUrl + '/usuarios/actualizar',
  updatePassword: baseUrl + '/users/actualizar_password',
  getHotelesUrl: baseUrl + '/establecimientos/',
  addHotelUrl: baseUrl + '/establecimientos/registro',
  removeHotelUrl: baseUrl + '/establecimientos/{uid}/eliminar',
  downloadJsonUrl: baseUrl + '/establecimientos/{uid}/descarga/csv',
  uploadFileUrl: baseUrl + '/establecimientos/{uid}/carga',
  downloadJsonADRUrl: baseUrl + '/establecimientos/{uid}/indicadores/adr/csv',
  downloadJsonRevParUrl:
    baseUrl + '/establecimientos/{uid}/indicadores/revpar/csv',
  getDataCardUrl: baseUrl + '/establecimientos/{uid}/diferencia',
  getCSVUrl: baseUrl + '/indicadores',
  getEstablishmentDataUrl:
    baseUrl +
    '/clm_datosabiertos/filtrar/?name={establecimiento}&establishment_type={tipoEstablecimiento}',
  getTableDatosCLM: baseUrl + '/clm_datosabiertos/{info_type}',
  alejandria: baseUrl + '/alejandria/request/',
  autocompleteName: baseUrl + '/establecimientos/buscar',
}
