//login.component.ts

import { Component, ViewChild } from '@angular/core'
import { AuthService } from '../../services/auth/auth.service'
import { ChangePassModalComponent } from '../../components/change-pass-modal/change-pass-modal.component'
import { Router } from '@angular/router'
import { Iuser } from '../../services/users/iuser'
import { UserDataService } from '../../services/users/userData.service'
import { firstValueFrom, map, Observable } from 'rxjs'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  showRegister: boolean = false
  waiting: boolean = false

  // Signup flow steps
  showConfirmationCodeInput: boolean = false
  showVinculateMFA: boolean = false
  showLoginMFA: boolean = false
  showResetPassword: boolean = false

  // Signup flow data
  signupData: any
  QRUrl: string

  // Reset password flow
  resetCodeSentToUser: boolean = false

  // Backend error handling
  userAlreadyExists: boolean = false
  errorMessage?: string
  successMessage?: string

  @ViewChild(ChangePassModalComponent) modal?: ChangePassModalComponent

  constructor(
    private router: Router,
    private authService: AuthService,
    private userDataService: UserDataService
  ) {}

  async signUp(formData: any) {
    this.signupData = formData

    try {
      this.waiting = true
      await this.authService.signUp(formData)

      this.showConfirmationCodeInput = true
      this.errorMessage = undefined
      this.waiting = false
    } catch (error: any) {
      if (error.code === 'UsernameExistsException') {
        this.userAlreadyExists = true
      } else {
        this.errorMessage = 'Error al registrar'
      }
      this.waiting = false
      console.error('Error while registering', error)
    }
  }

  async confirmSignup(confirmationCode: string) {
    this.waiting = true
    try {
      await this.authService.confirmSignUp(
        this.signupData.email,
        confirmationCode
      )
      this.setupMFA(
        this.signupData.username,
        this.signupData.password,
        this.signupData.email
      )

      this.errorMessage = undefined
      this.waiting = false
    } catch (err: any) {
      if (err.code === 'CodeMismatchException') {
        this.errorMessage = 'El código introducido no es correcto'
      } else {
        this.errorMessage = 'Error en la confirmación'
      }

      this.waiting = false

      console.error('Error while registering', err)
    }
  }

  async setupMFA(username: string, password: string, email: string) {
    try {
      const secretQRCode = await this.authService.setupMFA(email, password)

      this.QRUrl = this.authService.getQRImage(secretQRCode, email)

      this.showVinculateMFA = true
      this.showConfirmationCodeInput = false
    } catch (error) {
      console.error('Error while setting up MFA', error)
    }
  }

  async submitSignupTOTPcode(otpCode: string) {
    try {
      this.waiting = true

      const session = await this.authService.verifyMFA(otpCode)
      const sub = session.idToken.payload.sub

      const success = await this.registerUserToDB(this.signupData, sub)

      if (!success) throw { code: 'FailedToRegisterUser' }

      this.router.navigate(['/app'])
    } catch (err: any) {
      if (err.code === 'FailedToRegisterUser') {
        this.errorMessage = 'Error al registrar el usuario'
      } else if (err.code === 'InvalidParameterException') {
        this.errorMessage = 'El código introducido no es correcto'
      } else if (err.code === 'CodeMismatchException') {
        this.errorMessage = 'El código introducido no es correcto'
      } else if (err.code === 'EnableSoftwareTokenMFAException') {
        this.errorMessage = 'El código introducido no es correcto'
      } else {
        this.errorMessage = 'Error al vincular el MFA'
      }
    } finally {
      this.waiting = false
    }
  }

  async signIn(formData: any) {
    this.waiting = true

    try {
      const result = await this.authService.login(
        formData.email,
        formData.password
      )
      if (result.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.showLoginMFA = true
      }
      this.errorMessage = undefined
      this.waiting = false
    } catch (error: any) {
      this.waiting = false
      if (error.code === 'UserNotConfirmedException') {
        this.errorMessage = 'El usuario no ha sido confirmado'
      } else if (error.code === 'NotAuthorizedException') {
        this.errorMessage = 'Usuario o contraseña incorrectos'
      } else {
        this.errorMessage = 'Se requiere autenticación de dos factores'
      }
      console.error('Login error:', error)
    }
  }

  async submitSigninTOTPCode(mfaCode: string) {
    this.waiting = true
    try {
      await this.authService.verifyLoginTOTP(mfaCode)

      this.router.navigate(['/app'])
    } catch (err: any) {
      if (err.code === 'CodeMismatchException') {
        this.errorMessage = 'Código incorrecto'
      }
      this.waiting = false
    }
  }

  async registerUserToDB(signupData: any, sub: string) {
    const newUser: Iuser = {
      email: signupData.email,
      name: signupData.username,
      province: signupData.province,
      postal_code: signupData.postalCode,
      city: signupData.municipality,
      uid: sub,
    }

    try {
      const data = await this.userDataService.guardarUsuario(newUser)
      return data
    } catch (error) {
      console.error('Failed to register user:', error)
      return false
    }
  }

  async initiatePasswordReset(username: string) {
    this.waiting = true
    try {
      await this.authService.initiatePasswordReset(username)

      this.resetCodeSentToUser = true
      this.waiting = false
    } catch (error) {
      this.waiting = false
      this.errorMessage = 'Error al enviar el código de verificación'
      console.error('Password reset initiation failed:', error)
      throw error
    }
  }

  async confirmPasswordReset({
    email,
    code,
    newPassword,
  }: {
    email: string
    code: string
    newPassword: string
  }) {
    try {
      await this.authService.completePasswordReset(email, code, newPassword)

      this.closeResetPassword()
      this.successMessage = 'Contraseña cambiada correctamente'
    } catch (error) {
      this.errorMessage = 'Error al confirmar la nueva contraseña'
      console.error('Password reset confirmation failed:', error)
      throw error
    }
  }

  startResetPassword() {
    this.showResetPassword = true
  }

  closeResetPassword() {
    this.resetCodeSentToUser = false
    this.showResetPassword = false
  }

  showSignup() {
    this.errorMessage = undefined
    this.waiting = false
    this.showRegister = true
  }

  showSignin() {
    this.errorMessage = undefined
    this.waiting = false
    this.showRegister = false
  }
}
