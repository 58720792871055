<div>
  <label for="yearSelect">Selecciona un año:</label>
  <select
    id="yearSelect"
    [(ngModel)]="selectedYear"
    (change)="onYearChange($event)"
  >
    <option *ngFor="let year of yearsWithoutUndefined" [value]="year">
      {{ year }}
    </option>
  </select>
</div>
<div class="graph">
  <canvas #contribucionProv id="contribucionProv"></canvas>
</div>
